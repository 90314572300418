import type { EventParams } from './types';

let queueList: Array<Record<string, unknown>> = [];

function pushGTMEvent(params: EventParams) {
  if (!window.isGTMLoaded) {
    queueList.push(params);
    return;
  }

  window.dataLayer = window.dataLayer || [];
  if (queueList.length) {
    window.dataLayer.push(...queueList);
    queueList = [];
  }

  window.dataLayer.push(params);
}

function handleGTMScriptLoad() {
  if (!window.dataLayer || !Array.isArray(window.dataLayer)) return;

  window.isGTMLoaded = true;
  pushGTMEvent({
    event: 'gtm.js',
    'gtm.start': new Date().getTime()
  });
}

export const GTM = {
  push: pushGTMEvent,
  handleScriptLoad: handleGTMScriptLoad
};
