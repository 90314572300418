import type { FC } from 'react';

import { FeatureFlagContext } from './context';
import type { FeatureFlagProviderProps } from './types';

const FeatureFlagProvider: FC<FeatureFlagProviderProps> = ({ children, config }) => {
  return (
    <FeatureFlagContext.Provider value={config}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagProvider;
