import { useContext } from 'react';

import { OAuthContext } from './context';

export function useOAuth() {
  const ctx = useContext(OAuthContext);

  if (typeof ctx === 'undefined') {
    throw new Error('useOAuth was used outside of OAuthProvider');
  }

  return ctx;
}
