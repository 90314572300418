import sessionStorage from '@/services/sessionStorage';
import type { UserInfo } from '@/types/user/userInfo';


const key = 'dXNlclNlc3Npb25TdG9yYWdl';

const get = (): UserInfo | null => {
  const raw = sessionStorage.getItem(key);
  if (!raw) return null;
  try {
    return JSON.parse(raw);
  } catch {
    return null;
  }
};

const getID = (): string | null => get()?.guid ?? null;

const set = (user: UserInfo): void => {
  sessionStorage.setItem(key, JSON.stringify(user));
};

const clear = (): void => {
  sessionStorage.removeItem(key);
};

const userStorage = {
  get,
  getID,
  set,
  clear
};

export default userStorage;
