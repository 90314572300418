import fetcher from '@/repositories/fetcher';
import { getHeadersWithBasicAuth } from '@/repositories/utils';
import { getAccessToken } from '@/services/cookies/oauth';
import type { BaseResponseApi } from '@/types/general/responseApi';

import type { CustomerEligibleSubmission } from './types';

export async function fetchEligibleSubmission(): Promise<BaseResponseApi<CustomerEligibleSubmission>> {
  const result = await fetcher<BaseResponseApi<CustomerEligibleSubmission>>({
    url: '/api/customer/eligible-submission',
    method: 'GET',
    headers: {
      ...getHeadersWithBasicAuth(),
      Accept: 'application/json',
      'x-auth': getAccessToken()?.value ?? ''
    }
  });

  return result;
}
