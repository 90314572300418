import { useEffect, useMemo, useState } from 'react';

import { captureException } from '@sentry/nextjs';

import type { ParsedUserAgent } from '@/types/general/parsedUserAgent';

import { defaultParsedUserAgent } from './config';
import { ParsedUserAgentContext } from './context';
import type { ParsedUserAgentContextType, ParsedUserAgentProviderProps } from './types';

export default function ParsedUserAgentProvider({
  children,
  value
}: ParsedUserAgentProviderProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [parsedUserAgent, setParsedUserAgent] = useState<ParsedUserAgent>(
    value ?? defaultParsedUserAgent
  );

  useEffect(() => {
    if (value) return;

    // It will be executed if the loaded page is non server side page
    async function parseUserAgentInClientSide() {
      if (typeof navigator === 'undefined') return;

      try {
        setIsLoading(true);
        const uaParser = (await import('ua-parser-js')).default;
        const parsedUA = uaParser(navigator.userAgent || '');
        document.body.classList.add(parsedUA.device?.type || 'desktop');

        setParsedUserAgent({
          browser: parsedUA.browser,
          device: parsedUA.device,
          isMobile: parsedUA.device?.type === 'mobile',
          os: parsedUA.os
        });
      } catch (error) {
        captureException(error, {
          tags: {
            section: 'parse-ua-in-non-ssr-page'
          }
        });
      } finally {
        setIsLoading(false);
      }
    }

    parseUserAgentInClientSide();
  }, [value]);

  const contextValue = useMemo<ParsedUserAgentContextType>(() => ({
    ...parsedUserAgent,
    isLoading
  }), [parsedUserAgent, isLoading]);

  return (
    <ParsedUserAgentContext.Provider value={contextValue}>
      {children}
    </ParsedUserAgentContext.Provider>
  );
}
