/**
 * An abstraction layer for fetcher to be used on repositories
*/
import getConfig from 'next/config';

import type { FetcherParams } from './types';
import { FetchError, parseURL } from './utils';

const { serverRuntimeConfig } = getConfig();
const { appVersion, ciProjectName } = serverRuntimeConfig;

const fetcher = async<T>({
  url,
  method,
  body,
  headers,
  query,
  isFormData = false
}: FetcherParams): Promise<T> => {
  const response = await fetch(parseURL(url, query), {
    method,
    headers: {
      ...(
        typeof window === 'undefined'
        && { 'user-agent': `Pinhome/${ciProjectName}/${appVersion}` }
      ),
      ...headers
    },
    ...(body && { body: isFormData ? body as FormData : JSON.stringify(body) })
  });

  const { status } = response;
  if (status >= 400 && status < 600) throw new FetchError(response);

  const data = await response.json();
  return data;
};

export default fetcher;
