let mockedStorage: Record<string, string> = {};

const mockedSessionStorage: Storage = {
  length: Object.keys(mockedStorage).length,
  setItem(key, value) {
    mockedStorage[key] = value;
  },
  getItem(key) {
    return mockedStorage[key] || null;
  },
  removeItem(key) {
    delete mockedStorage[key];
  },
  clear() {
    mockedStorage = {};
  },
  key(index) {
    return Object.values(mockedStorage)[index] || null;
  }
};

function getSessionStorage(): Storage {
  if (process.env.NODE_ENV === 'test') {
    return mockedSessionStorage;
  }

  try {
    if (typeof window === 'undefined' || typeof (Storage) === 'undefined') {
      throw new Error('Session Storage is not available, either on server side or browser is not supported.');
    }
    return window.sessionStorage || mockedSessionStorage;
  } catch {
    return mockedSessionStorage;
  }
}

const sessionStorage: Storage = {
  get length() {
    return getSessionStorage().length;
  },
  setItem(key, value) {
    getSessionStorage().setItem(key, value);
  },
  getItem(key) {
    return getSessionStorage().getItem(key);
  },
  removeItem(key) {
    getSessionStorage().removeItem(key);
  },
  clear() {
    getSessionStorage().clear();
  },
  key(index) {
    return getSessionStorage().key(index);
  }
};

export default sessionStorage;
