import type { IncomingMessage, ServerResponse } from 'http';

import { ONE_SECOND_IN_MS, ONE_YEAR_IN_SECONDS } from '@/constants/time';
import cookie from '@/services/cookies';

interface AccessToken {
  value: string,
  exp: number,
};

const accessTokenKey = '_ptokv2';

function getAccessTokenExpieredUnixTimestamp() {
  return Math.round(
    Date.now() / ONE_SECOND_IN_MS
    + ONE_YEAR_IN_SECONDS
  );
}

export const setTokenCookie = (accessToken: string, res?: ServerResponse) => {
  const accessTokenCookie: AccessToken = {
    value: accessToken,
    exp: ONE_YEAR_IN_SECONDS * ONE_SECOND_IN_MS
  };

  cookie.add({
    name: accessTokenKey,
    value: JSON.stringify(accessTokenCookie),
    unixTimestamp: getAccessTokenExpieredUnixTimestamp()
  }, res);
};

export const getAccessToken = (
  request?: IncomingMessage
): AccessToken | null => {
  const accessTokenCookie = cookie.get(accessTokenKey, request);
  if (!accessTokenCookie) return null;

  try {
    return JSON.parse(accessTokenCookie);
  } catch {
    return null;
  }
};

export const deleteAccessToken = (res?: ServerResponse) => {
  cookie.delete(accessTokenKey, res);
};
