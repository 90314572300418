import getConfig from 'next/config';

import type { CustomerEligibleSubmission } from '@/repositories/customer/eligible-submission/types';
import fetcher from '@/repositories/fetcher';
import { getHeadersWithBasicAuth } from '@/repositories/utils';
import type { BaseResponseApi } from '@/types/general/responseApi';
import type { UserInfo } from '@/types/user/userInfo';

export async function fetchUserInfo(token: string): Promise<BaseResponseApi<UserInfo>> {
  const result = await fetcher<BaseResponseApi<UserInfo>>({
    url: '/api/customer/get-customer-detail',
    method: 'GET',
    headers: {
      ...getHeadersWithBasicAuth(),
      Accept: 'application/json',
      'x-auth': token
    }
  });

  return result;
};

export async function fetchUserInfoSSR(token: string): Promise<BaseResponseApi<UserInfo>> {
  const { serverRuntimeConfig } = getConfig();

  const result = await fetcher<BaseResponseApi<UserInfo>>({
    url: `${serverRuntimeConfig.pinfinance.internalPinfinanceUrl}/customers/detail`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  return result;
};

export async function fetchUserEligibleSubmissionSSR(token: string): Promise<BaseResponseApi<CustomerEligibleSubmission>> {
  const { serverRuntimeConfig } = getConfig();

  const result = await fetcher<BaseResponseApi<CustomerEligibleSubmission>>({
    method: 'GET',
    url: `${serverRuntimeConfig.pinfinance.internalPinfinanceUrl}/customers/eligibility`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return result;
}
