import getConfig from 'next/config';

import type { EventTrackingParam, IEventTracking, ProfileEventParam, StandarizedProfileEvent } from '@/services/eventTracking/types';

const { publicRuntimeConfig } = getConfig();

export const transformUserProfile = (params: ProfileEventParam): StandarizedProfileEvent => {
  if (params.phone.startsWith('62')) {
    params.phone = `+${params.phone}`;
  }

  return {
    Name: params.name,
    Identity: params.identity,
    Email: params.email,
    Gender: params.gender,
    DOB: params.dob,
    Phone: params.phone,
    account_guid: params.guid,
    'MSG-email': params.msgEmail || false,
    'MSG-push': params.msgPush || false,
    'MSG-sms': params.msgSms || false,
    'MSG-whatsapp': params.msgWhatsApp || false
  };
};


export class CleverTapService implements IEventTracking {

  async getID() {
    try {
      const clevertap = await import('clevertap-web-sdk');
      return clevertap.default.getCleverTapID();
    } catch (e) {
      // TODO : implemented soon if we already implement auth
      return null;
    }
  };

  async pushEvent(params: EventTrackingParam) {
    try {
      const clevertap = await import('clevertap-web-sdk');

      const cleverTapID = await this.getID();

      clevertap.event.push(params.event, {
        clever_tap_id: cleverTapID,
        ...params.eventProps
      });
    } catch (e) {
      //TODO : implemented soon sentry or other logging application
    }
  }

  async init() {
    const cleverTapAccountID: string = publicRuntimeConfig.clevertapAccountID ?? '';

    if (cleverTapAccountID === '') {
      throw 'clever tap account id is null';
    }

    const clevertap = await import('clevertap-web-sdk');

    clevertap.default.init(cleverTapAccountID);
    clevertap.privacy.push({ optOut: false });
    clevertap.privacy.push({ useIP: true });
  }


  async pushUserProfile(params: ProfileEventParam) {
    const userProfile = transformUserProfile(params);
    try {
      const clevertap = await import('clevertap-web-sdk');
      clevertap.onUserLogin.push({
        Site: userProfile
      });
    } catch (e) {
      //TODO : implemented soon sentry or other logging application
    }
  };

}
