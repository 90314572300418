import { useCallback, useEffect, useState } from 'react';

import { captureException, setUser } from '@sentry/nextjs';

import { fetchUserInfo } from '@/repositories/auth/userInfo';
import { fetchEligibleSubmission } from '@/repositories/customer/eligible-submission/fetcher';
import { deleteAccessToken, getAccessToken, setTokenCookie } from '@/services/cookies/oauth';
import userStorage from '@/services/sessionStorage/user';
import type { UserInfo } from '@/types/user/userInfo';

import { OAuthContext } from './context';
import type { LoginParams, OAuthProviderProps } from './types';

export default function OAuthProvider({
  children,
  userInfo,
  isEligibleSubmission
}: OAuthProviderProps) {
  const [currentUser, setCurrentUser] = useState(userInfo ?? null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEligible, setIsEligible] = useState(isEligibleSubmission ?? false);

  const login = useCallback(({ accessToken, ...newUser }: LoginParams) => {
    setTokenCookie(accessToken);
    setCurrentUser(newUser);
  }, []);

  const logout = useCallback(() => {
    setCurrentUser(null);
    deleteAccessToken();
  }, []);

  const updateUserInfo = useCallback((newUserInfo: UserInfo) => {
    setCurrentUser(newUserInfo);
  }, []);

  useEffect(() => {
    if (typeof userInfo !== 'undefined') return;

    // It will be executed if the loaded page is non server side page
    const token = getAccessToken()?.value;
    if (!token) return;

    setIsLoading(true);
    fetchUserInfo(token)
      .then(user => setCurrentUser(user.data))
      .catch(error => captureException(error, { tags: { section: 'fetch-user-info-error-in-OAuthProvider' } }))
      .finally(() => setIsLoading(false));
    fetchEligibleSubmission()
      .then(data => setIsEligible(data.data.eligible))
      .catch(error => captureException(error, { tags: { section: 'fetch-user-eligibility-error-in-OAuthProvider' } }));

  }, [userInfo]);

  useEffect(() => {
    if (currentUser) {
      userStorage.set(currentUser);
      setUser({
        email: currentUser.email,
        id: `${currentUser.guid}`,
        username: currentUser.phoneNumber
      });
    } else {
      userStorage.clear();
      setUser(null);
    }
  }, [currentUser]);

  return (
    <OAuthContext.Provider
      value={{
        isLoading,
        login,
        logout,
        updateUserInfo,
        user: currentUser,
        isEligibleSubmission: isEligible
      }}
    >
      {children}
    </OAuthContext.Provider>
  );
}
