import '@/styles/globals.css';
import '@/styles/_yggdrasil.css';

import { useEffect } from 'react';

import type { NextPage } from 'next';
import type { AppProps as NextAppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import Script from 'next/script';

const { publicRuntimeConfig } = getConfig();

import { useOAuth } from '@/contexts/oauth';
import Provider from '@/contexts/Provider';
import EventTrackingService from '@/services/eventTracking';
import { GTM } from '@/services/gtm';
import eventNames from '@/services/gtm/eventNames';
import type { AppPageProps } from '@/types/general/appPageProps';

// // Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function AppHeadTags() {
  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="manifest" href="/manifest.json" />
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="application-name" content={publicRuntimeConfig.appName} />
      <meta name="theme-color" content={publicRuntimeConfig.manifestThemeColor} />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-title" content={publicRuntimeConfig.appName} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="msapplication-navbutton-color" content={publicRuntimeConfig.manifestThemeColor} />
      <meta name="msapplication-TileColor" content={publicRuntimeConfig.manifestBackgroundColor} />
      <meta name="msapplication-starturl" content={publicRuntimeConfig.manifestStartUrl} />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="msapplication-tap-highlight" content="no" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicon.png"
        sizes="32x32"
      />
      <link
        rel="shortcut icon"
        href="/favicon.ico"
      />
      {publicRuntimeConfig.manifestIconFileNames.map((fileName: any) => {
        const [, size] = fileName.split('-');
        const sizes = `${size}x${size}`;
        const href = `/static/images/${fileName}.png`;
        return (
          <link key={fileName} href={href} sizes={sizes} rel="icon" type="image/png" />
        );
      })}
    </Head>
  );
}

const gtmID = publicRuntimeConfig.googleTagManagerID;

const iframe = `
  <iframe
    src="//www.googletagmanager.com/ns.html?id=${gtmID}"
    height="0"
    width="0"
    style="display:none;visibility:hidden"
  ></iframe>
`;

function GoogleTagManager() {
  const { user } = useOAuth();

  useEffect(() => {
    if (user) {
      GTM.push({
        event: eventNames.userRevisit,
        user: {
          id: user.guid
        }
      });
    }
  }, [user]);

  return (
    <>
      <Script
        onLoad={GTM.handleScriptLoad}
        src={`https://www.googletagmanager.com/gtm.js?id=${gtmID}`}
        strategy="afterInteractive"
      />
      <noscript dangerouslySetInnerHTML={{ __html: iframe }} />
    </>
  );
}

function TrackingEvent() {
  useEffect(() => {
    EventTrackingService.init();
  }, []);

  return null;
}

interface AppProps extends Omit<NextAppProps, 'Component' | 'pageProps'> {
  Component: NextPage<AppPageProps>;
  pageProps: AppPageProps;
}

function App({ Component, pageProps }: AppProps) {
  return (
    <Provider
      featureFlagConfig={pageProps.featureFlagConfig}
      parsedUserAgent={pageProps.parsedUserAgent ?? null}
      userInfo={pageProps.userInfo}
      isEligibleSubmission={pageProps.isEligibleSubmission}
    >
      <AppHeadTags />
      <GoogleTagManager />
      <TrackingEvent />
      <Component {...pageProps} />
    </Provider>
  );
}

export default App;
