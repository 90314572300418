import FeatureFlagProvider from './featureFlag/FeatureFlagProvider';
import OAuthProvider from './oauth/OAuthProvider';
import ParsedUserAgentProvider from './parsedUserAgent/ParsedUserAgentProvider';
import type { ProviderProps } from './types';

export default function Provider({
  children,
  featureFlagConfig,
  parsedUserAgent,
  userInfo,
  isEligibleSubmission
}: ProviderProps) {
  return (
    <FeatureFlagProvider config={featureFlagConfig}>
      <ParsedUserAgentProvider value={parsedUserAgent}>
        <OAuthProvider userInfo={userInfo} isEligibleSubmission={isEligibleSubmission}>
          {children}
        </OAuthProvider>
      </ParsedUserAgentProvider>
    </FeatureFlagProvider>
  );
}
