import type { ParsedUserAgent } from '@/types/general/parsedUserAgent';

export const defaultParsedUserAgent: ParsedUserAgent = {
  browser: {
    major: undefined,
    name: undefined,
    version: undefined
  },
  device: {
    model: undefined,
    type: undefined,
    vendor: undefined
  },
  isMobile: false,
  os: {
    name: undefined,
    version: undefined
  }
};
