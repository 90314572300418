import { captureException } from '@sentry/nextjs';
import getConfig from 'next/config';

export function parseURL(url: string, query?: Record<string, any>): string {
  const [urlWithoutQueryString, initialQueryString] = url.split('?');

  const searchParams = new URLSearchParams(initialQueryString);
  const arrayTypeSearchParam: any = {};

  if (query) {
    for (const key in query) {
      const value = query[key];
      if (!Array.isArray(value)) {
        searchParams.append(key, typeof value === 'string' ? value : JSON.stringify(value));
      } else {
        arrayTypeSearchParam[key] = value;
      }
    }
  }

  const search = searchParams.toString();
  let queryString = search || Object.keys(arrayTypeSearchParam).length > 0 ? `?${search}` : '';

  if (Object.keys(arrayTypeSearchParam).length > 0) {
    let duplicateQueryString = [];

    for (const key in arrayTypeSearchParam) {
      duplicateQueryString.push(`${key}=${(arrayTypeSearchParam[key]).join(`&${key}=`)}`);
    }

    queryString = queryString.concat('&', duplicateQueryString.join('&'));

  }

  return `${urlWithoutQueryString}${queryString}`;
};

export function getHeadersWithBasicAuth(): Record<string, string> {
  const { publicRuntimeConfig } = getConfig();

  if (!publicRuntimeConfig.isDev) return {};

  return {
    Authorization: `Basic ${publicRuntimeConfig.stagingDevBasicAuth}`
  };
};

const INTERNAL_SERVER_ERROR_STATUS = 500;
const INTERNAL_SERVER_ERROR_MESSAGE = 'Internal Server Error';

export class FetchError extends Error {
  response: Response;

  constructor(response: Response) {
    super(`${response.status} ${response.statusText}`);
    this.response = response;
  }
}

export async function parseError<TError extends Error = Error>(error: TError) {
  if (!(error instanceof FetchError)) {
    return {
      status: INTERNAL_SERVER_ERROR_STATUS,
      data: {
        message: error?.message || INTERNAL_SERVER_ERROR_MESSAGE
      }
    };
  }

  try {
    const { response } = error;
    const { status } = response;
    if (response.headers.get('Content-Type')?.includes('text')) {
      const message = await response.text();
      return {
        status,
        data: {
          message
        }
      };
    }

    return {
      status,
      data: await response.json()
    };
  } catch (err) {
    captureException(err, {
      tags: {
        section: 'failed-on-parsing-error'
      }
    });
    return {
      status: INTERNAL_SERVER_ERROR_STATUS,
      data: {
        message: INTERNAL_SERVER_ERROR_MESSAGE
      }
    };
  }
}

export const httpStatus = {
  badRequest: {
    code: 400
  },
  internalServerError: {
    code: 500,
    message: 'Internal Server Error'
  },
  methodNotAllowed: {
    code: 405,
    message: 'Method Not Allowed'
  },
  unauthorized: {
    code: 401,
    message: 'Unauthorized'
  },
  ok: {
    code: 200
  }
} as const;

type ReducerAction = {
  type: 'loading' | 'error' | 'data',
  payload?: any;
};

type ReducerState<T> = {
  isLoading: boolean;
  error: any;
  data: T;
};

export const reducerInitialState = {
  isLoading: false,
  error: null,
  data: null
};

export const fetchReducer = <T>(state: ReducerState<T>, action: ReducerAction) => {
  switch (action.type) {
    case 'data':
      return { isLoading: false, error: null, data: action.payload };
    case 'loading':
      return { ...state, isLoading: action.payload };
    case 'error':
      return { isLoading: false, error: action.payload, data: null };
    default:
      return { ...state };
  }
};

