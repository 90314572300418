import type { IncomingMessage, ServerResponse } from 'http';

import type { CookieValue, RawCookie } from './types';

const cookie = {
  generateValue(name: string, value: CookieValue, unixTimestamp?: number): string {
    let val = `${name}=${value || ''}`;
    if (unixTimestamp != null) {
      const date = new Date(unixTimestamp * 1000);
      val += `; expires=${date.toUTCString()}`;
    }

    return `${val}; path=/`;
  },

  add(rawCookie: RawCookie, res?: ServerResponse) {
    const { name, value, unixTimestamp } = rawCookie;
    const cookieValue = cookie.generateValue(name, value, unixTimestamp);
    if (res) {
      res.setHeader('Set-Cookie', [cookieValue]);
    } else if (typeof document !== 'undefined') {
      document.cookie = cookieValue;
    }
  },

  set(rawCookies: RawCookie[], res?: ServerResponse) {
    const cookies = rawCookies.map((rawCookie) => cookie.generateValue(
      rawCookie.name,
      rawCookie.value,
      rawCookie.unixTimestamp
    ));
  
    if (res) {
      res.setHeader('Set-Cookie', cookies);
    } else if (typeof document !== 'undefined') {
      cookies.forEach((value) => {
        document.cookie = value;
      });
    }
  },

  get(name: string, request?: IncomingMessage): CookieValue {
    const nameEQ = `${name}=`;
    let requestCookies: string[] = [];

    if (request) {
      requestCookies = request.headers?.cookie?.split(';') || [];
    } else if (typeof document !== 'undefined') {
      requestCookies = document.cookie.split(';');
    }

    for (let i = 0; i < requestCookies.length; i++) {
      let cookies = requestCookies[i];
      while (cookies.charAt(0) === ' ') cookies = cookies.substring(1, cookies.length);
      if (cookies.indexOf(nameEQ) === 0) return cookies.substring(nameEQ.length, cookies.length);
    }

    return null;
  },

  delete(name: string, res?: ServerResponse) {
    cookie.add({ name, unixTimestamp: 0, value: null }, res);
  }
};

export default cookie;